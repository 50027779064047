import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components}>
    <h1 {...{
      "id": "blog"
    }}>{`Blog`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/blog/custom-pragma"
        }}>{`Custom pragma`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      